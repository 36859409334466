import React from 'react'
import Accordionweb from './Accordian'

function Investors() {
  return (
    <div>
         
      <Accordionweb/>
    </div>
  )
}

export default Investors
